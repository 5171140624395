<template>
  <section>
    <a-page-header @back="$router.push({ name: 'main' })" :class="{ loading }"
      style="padding: 1.25rem 0 0.25rem; margin-bottom: 0" v-if="searchMode === 'free_slots'">
      <template #title>Свободные</template>
    </a-page-header>

    <a-page-header @back="$router.push({ name: 'main' })" title="Свободные" v-else
      style="padding: 1.25rem 0 0.25rem; margin-bottom: 0">
      <template #title>Свободные</template></a-page-header>

    <main>
      <a-row :gutter="54">
        <a-col :span="16">
          <main v-if="data?.length > 0 || loading" class="ant-spin-nested-loading">
            <div class="spin-container" v-if="loading">
              <a-spin />
            </div>
            <!-- <p class="small" v-if="searchMode === 'reservations'">Ближайшие бронирования</p> -->
            <section class="ant-spin-container" :class="{ 'ant-spin-blur': loading }">
              <list-reservation-card v-for="reservation in data" :key="reservation?.id" :reservation="reservation" is-slot
                :allow-reserve-time="formSearchDate.freeTimeFrom &&
                  formSearchDate.freeTimeFromValid
                  " :disabled="loading" isFromSlots />
            </section>
            <div v-if="searchMode === 'free_slots' &&
              data?.length >= 100 &&
              !loading
              " class="disabled-color-text" :style="{
    padding: '1.5rem 0 2rem',
    margin: 0,
    clear: 'both',
  }">
              Чтобы увидеть больше вариантов бронирования,
              уточните критерии поиска
            </div>
          </main>
          <main v-else>
            <p class="grayscale-gray-color-text">
              Уточните параметры поиска
            </p>
          </main>
        </a-col>
        <a-col :span="8" style="padding-left: 5px">
          <nav class="filters-container">
            <div type="flex" justify="space-between" align="start" :style="{ marginBottom: '0.5rem' }">
              <h5 :style="{ marginBottom: '1rem' }">
                Что вы ищете?
              </h5>
              <a-button size="small" type="dashed" class="grayscale-gray-color-text icon-right"
                :style="{ marginBottom: '0.75rem' }" @click="resetFilters(true)">
                <template #icon>
                  <close-outlined />
                </template>
                Очистить
              </a-button>
            </div>

            <a-form layout="vertical">
              <a-form-item label="Локация" name="location">
                <object-tree-select v-model="formSearch.objectId" placeholder="Введите название объекта" hide-structure />
              </a-form-item>

              <a-form-item label="Тип объекта">
                <a-select v-model:value="formSearch.objectTypeId" show-search :loading="objectTypesLoading"
                  :ref="reservationTypeRef" :filter-option="false" not-found-content="Ничего не найдено"
                  @search="getObjectTypes" placeholder="Выберите тип" size="large" class="selectorCustom">
                  <a-select-option :value="option.id" v-for="option in objectTypesData" :key="option.id">
                    {{ option.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>

              <a-form-item label="Оборудование" v-if="formSearch.objectTypeId ===
                '545c834a-dc0f-4402-965a-b6fdaa8725de'
                ">
                <a-select v-model:value="formSearch.reservationTypeId" show-search :default-active-first-option="false"  mode="multiple"
                  :filter-option="false" not-found-content="Ничего не найдено" @search="getReservationTypes"
                  placeholder="Выберать" size="large" class="selectorCustom">
                  <a-select-option :value="option.id" v-for="option in EquipOptions" :key="option.id">
                    {{ option.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>


              <a-form-item label="Расположение" v-if="formSearch.objectTypeId ===
                '545c834a-dc0f-4402-965a-b6fdaa8725de'
                ">
                <div>
                  <a-button @click="rightButtonClick" class="roundButtonR" :class="{ active: rightActive }" size="large">У
                    прохода</a-button>
                  <a-button @click="leftButtonClick" class="roundButtonL" :class="{ active: leftActive }" size="large">У
                    окна</a-button>
                </div>
              </a-form-item>
              <a-form-item label="Размер" v-if="formSearch.objectTypeId ===
                '611699c2-85e5-4dae-b810-6d18fc5bcee8'
                ">
                <div>
                  <a-button @click="rightButtonClickSize" class="roundButtonRLoker" :class="{ active: rightActiveSize }"
                    size="large">S</a-button>
                  <a-button @click="centralButtonClickSize" class="centerButton" :class="{ active: centerActiveSize }"
                    size="large">M</a-button>
                  <a-button @click="leftButtonClickSize" class="roundButtonLLoker" :class="{ active: leftActiveSize }"
                    size="large">L</a-button>
                </div>
              </a-form-item>

              <a-form-item label="Размер" v-if="formSearch.objectTypeId ===
                '545c834a-dc0f-4402-965a-b6fdaa8725de'
                ">
                <div>
                  <a-button @click="rightButtonClickSize" class="roundButtonR" :class="{ active: rightActiveSize }"
                    size="large">Высокое</a-button>
                  <a-button @click="leftButtonClickSize" class="roundButtonL" :class="{ active: leftActiveSize }"
                    size="large">Низкое</a-button>
                </div>
              </a-form-item>


              <a-form-item label="Количество мест"
                v-if="formSearch.objectTypeId === 'e3fc558b-13f6-4c33-83b0-5b89f10e620d'">
                <div class="placesCountInput">
                  <a-button @click="minusButtonClick" type="ghost" shape="circle" size="large"
                    style="background: #f2f2f7 !important;"><template #icon>
                      <MinusOutlined />
                    </template></a-button>

                  <a-input-number v-model:value="placesCount" :min="1"
                    style="width: 45px; background: #f2f2f7 !important; padding: 0 !important;" />
                  <a-button @click="plusButtonClick" type="ghost" shape="circle" size="large"
                    style="background: #f2f2f7 !important;"><template #icon>
                      <PlusOutlined />
                    </template></a-button>
                </div>
              </a-form-item>

              <a-form-item label="Оборудование" v-if="formSearch.objectTypeId === 'e3fc558b-13f6-4c33-83b0-5b89f10e620d'
                ">
                <a-select v-model:value="formSearch.reservationTypeId" show-search :default-active-first-option="false"
                  :filter-option="false" not-found-content="Ничего не найдено" @search="getReservationTypes"
                  placeholder="Выберать" size="large" class="selectorCustom" mode="multiple">
                  <a-select-option :value="option.id" v-for="option in EquipOptions" :key="option.id">
                    {{ option.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>

              <a-form-item label="Начало" name="date">
                <a-row :gutter="16" style="
                                        display: flex;
                                        justify-content: space-between;
                                    ">
                  <a-col  :xs="{ span: 24 }" :xl="{ span: 12 }" style="margin-bottom: 15px">
                    <a-date-picker v-model:value="formSearchDate.freeDateFrom
                      " placeholder="Дата" size="large" class="w-100" format="DD.MM.YY" value-format="YYYY-MM-DD"
                      @change="dateChanged('dateFrom')" />
                  </a-col>
                  <a-col  :xs="{ span: 24 }" :xl="{ span: 12 }">
                    <smart-time-field v-model="formSearchDate.freeTimeFrom
                      " v-model:valid="formSearchDate.freeTimeFromValid
    " @change="timeChanged('timeFrom')" style="width: 100%" />
                  </a-col>
                </a-row>
              </a-form-item>
              <a-form-item label="Продолжительность" name="duration">
                <smart-time-field v-model="formSearchDate.interval" v-model:valid="formSearchDate.intervalValid"
                  as-duration />
              </a-form-item>

              <a-form-item class="offset-md">
                <a-button size="large" type="primary" block @click="findFreeSlots" :loading="loading"
                  :disabled="!formSearch.objectId">
                  Подобрать варианты
                </a-button>
              </a-form-item>
            </a-form>
          </nav>
        </a-col>
      </a-row>
    </main>
  </section>
</template>

<script>
import { onMounted } from "@vue/runtime-core";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons-vue";
import { getListData as getObjectTypesData } from "@/compositions/objectTypes";
import { getListData as getReservationsData } from "@/compositions/reservations";
import SmartTimeField from "@/components/admin/shared/ul/SmartTimeField";
import ObjectTreeSelect from "@/components/admin/objects/ObjectTreeSelect";
import ListReservationCard from "@/components/admin/reservation/ListReservationCard";
import { useRoute } from "vue-router";
import { unref, ref } from "@vue/reactivity";
import { notification } from "ant-design-vue";

export default {
  name: "UserReservationListSlotsPage",
  setup() {
    const rightActive = ref(true);
    const leftActive = ref(false);
    const centerActive = ref(false);
    const placesCount = ref(1);

    const reservationTypeRef = ref()

    const rightActiveSize = ref(true);
    const leftActiveSize = ref(false);
    const centerActiveSize = ref(false);

    const EquipOptions = ref([
      {
        id: 1,
        name: "Стулья",


      },
      {
        id: 2,
        name: "Проектор",


      },
      {
        id: 3,
        name: "Принтер",

      },
      {
        id: 4,
        name: "Рабочий стол",

      },
      {
        id: 5,
        name: "Компьютер",

      },

    ]);


    const route = useRoute();
    const {
      loading: objectTypesLoading,
      fetchList: fetchObjectTypes,
      data: objectTypesData,
      search: objectTypesSearch,
      withPermission: objectTypesWithPermission,
    } = getObjectTypesData();
    const {
      DEFAULT_INTERVAL,
      loading,
      isFilterSelected,
      data,
      formSearchDate, // pagination,
      formSearch,
      findReservation,
      resetFilters,
      clientMode,
      searchMode,
      initFilterDefaults,
      dateChanged,
      timeChanged,
    } = getReservationsData();


    onMounted(async () => {
      formSearch.objectId = 'f688f544-3a07-4d54-bb6a-9fc0dccbc70a';
      searchMode.value = "reservations";
      clientMode.value = "user";
      objectTypesWithPermission.value = true;
      formSearch.treeSearch = "all";


      fetchObjectTypes();
      // Поставим текущие даты
      await initFilterDefaults();
      if (route.params?.objectId) {
        // Get data


        searchMode.value = route.params?.searchMode || "free_slots";
      }
      // Дополнительно заполняем параметры из предыдущего фильтра
      if (route.params?.dateFrom && route.params?.dateFrom !== "null") {
        formSearchDate.freeDateFrom = route.params.dateFrom;
      }
      if (route.params?.timeFrom && route.params?.timeFrom !== "null") {
        formSearchDate.freeTimeFrom = route.params.timeFrom;
      }
      if (route.params?.interval) {
        formSearchDate.interval =
          route.params.interval || DEFAULT_INTERVAL;
      }

      if (route.params.type === 'Рабочие места') {
        formSearch.objectTypeId = '545c834a-dc0f-4402-965a-b6fdaa8725de';
        await findFreeSlots();
      }

      if (route.params.type === 'Переговорные') {
        formSearch.objectTypeId = 'e3fc558b-13f6-4c33-83b0-5b89f10e620d';
        await findFreeSlots();
      }

      if (route.params.type === 'Парковки') {
        formSearch.objectTypeId = '277c0248-f39b-4720-9e3a-7bcc2bf84df7';
        await findFreeSlots();
      }

      if (route.params.type === 'Локеры') {
        formSearch.objectTypeId = '611699c2-85e5-4dae-b810-6d18fc5bcee8';
        await findFreeSlots();
      }
       await findFreeSlots();
    });
    async function getObjectTypes(search) {
      objectTypesSearch.value = search;
      fetchObjectTypes();
    }

    function rightButtonClick() {
      leftActive.value = false;
      rightActive.value = true;
    }

    function leftButtonClick() {
      leftActive.value = true;
      rightActive.value = false;
    }

    function rightButtonClickSize() {
      leftActiveSize.value = false;
      rightActiveSize.value = true;
      centerActiveSize.value = false;

    }

    function leftButtonClickSize() {
      leftActiveSize.value = true;
      rightActiveSize.value = false;
      centerActiveSize.value = false;

    }

    function centralButtonClickSize() {
      leftActiveSize.value = false;
      centerActiveSize.value = true;
      rightActiveSize.value = false;
    }

    function plusButtonClick() {
      placesCount.value = placesCount.value + 1;
    }

    function minusButtonClick() {
      placesCount.value = placesCount.value - 1;
    }


    async function findFreeSlots() {
      console.log(formSearch.objectTypeId);
      searchMode.value = "free_slots";
      await findReservation();
      if (unref(data).length === 0) {
        notification.info({
          message: "Ничего не найдено",
          description: "Уточните параметры поиска",
        });
      }
    }



    return {
      loading,
      formSearch,
      formSearchDate,
      dateChanged,
      timeChanged,
      data,
      searchMode,
      objectTypesLoading,
      fetchObjectTypes,
      objectTypesData,
      EquipOptions,
      objectTypesSearch,
      getObjectTypes,
      isFilterSelected,
      findFreeSlots,
      resetFilters,
      rightButtonClick,
      leftButtonClick,
      rightActive,
      leftActive,
      rightActiveSize,
      leftActiveSize,
      rightButtonClickSize,
      leftButtonClickSize,
      placesCount,
      plusButtonClick,
      minusButtonClick,
      centerActive,
      centerActiveSize,
      centralButtonClickSize,
      reservationTypeRef
    };
  },
  components: {
    ListReservationCard,
    ObjectTreeSelect,
    SmartTimeField,
    PlusOutlined,
    MinusOutlined,
  },
};
</script>

<style lang="less" scoped>
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 12px !important;
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.roundButtonR {
  width: 50%;
  border-radius: 12px 0 0 12px;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.active {
  border: none !important;
  color: #fff !important;
  background: linear-gradient(270deg, #5ebdc0 0%, #139ea3 98.8%) !important;
}

.roundButtonL {
  width: 50%;
  border-radius: 0 12px 12px 0;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.centerButton {
  width: 32%;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);

  border-left: none;
  border-right: none;
}

.roundButtonRLoker {
  width: 33%;
  border-radius: 12px 0 0 12px;

  border: 1px solid rgba(0, 0, 0, 0.12);
}

.roundButtonLLoker {
  width: 33%;
  border-radius: 0 12px 12px 0;
  border: 1px solid rgba(0, 0, 0, 0.12);
}



.filters-container {
  border-radius: 24px;
  background: #f2f2f7;
  padding: 24px;
  @media (max-width: 992px) {
    padding: 20px 15px;
  }
}

.placesCountInput {
  width: 100%;
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
